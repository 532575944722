window.includeSsiTemplates = includeSsiTemplates;

function includeSsiTemplates(layout, type) {
  function load(url, callback) {
    var xhr = new XMLHttpRequest();
    try {
      xhr.open('GET', url, true);
      xhr.setRequestHeader('withCredentials', true);
      xhr.onload = function (e) {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          callback(xhr);
        }
      };
      xhr.send();
    } catch (e) {
      callback(false);
    }
  }

  function initApp(appUrl, container) {
    try {
      load(appUrl, function (xhr) {
        if (xhr.status !== 200) {
          throw new Error('Error fetch ' + appUrl);
        }
        if (xhr && xhr.response && xhr.status === 200) {
          try {
            var root = document.getElementById(container);
            if (root) {
              root.setAttribute('data-layout', layout);
              root.setAttribute('data-page', type);
              var newFragment = document
                .createRange()
                .createContextualFragment(xhr.response);
              root.appendChild(newFragment);

              if (container === 'ssi_banner_root_desktop') {
                new Promise(() =>
                  setTimeout(() => {
                    const banners = document
                      .querySelector('#ssi_banner_root_desktop > #root')
                      .cloneNode(true);
                    const mobile_root = document.getElementById(
                      'ssi_banner_root_mobile'
                    );
                    console.log({ banners, mobile_root });
                    mobile_root.appendChild(banners);
                  }, 1000)
                );
              }
            } else {
              throw new Error(container + ' not found');
            }
          } catch (e) {
            throw new Error(
              'Error insert newFragment to ' + container + '. ' + e
            );
          }
        }
      });
    } catch (e) {
      console.error(appUrl, e);
    }
  }

  if (document.getElementById('ssi_header_root'))
    initApp('/ssi/headers/index.html', 'ssi_header_root');
  if (document.getElementById('ssi_footer_root'))
    initApp('/ssi/footers/index.html', 'ssi_footer_root');
  if (document.getElementById('ssi_banner_root_desktop'))
    initApp('/ssi/banners/index.html', 'ssi_banner_root_desktop');
}
